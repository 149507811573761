import { navigateWithParams } from '@creditasmx-utilities/helpers';
import { BaseRoutes } from './baseRoutes';
import { ROUTES } from './routes';

const prefix = (location: Location, ...prefixes: string[]): boolean =>
  prefixes.some(
    (prefix) =>
      location.pathname === prefix || location.pathname === `${prefix}/`,
  );

const mfExternalAutoForm = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.AUTO_EXTERNAL);

const mfWelcome = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.WELCOME);

const mfAutoForm = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.AUTO);

const mfFormBuroQuery = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.PERSONAL_INFO);

const mfAddressForm = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.ADDRESS_INFO);

const mfNip = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.NIP);

const mfDocumentsForm = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.DOCUMENTS);

const mfFormIncome = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.INCOME);

const mfRejectedThirtyDays = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_THIRTY_DAYS);

const mfPreQualification = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.PRE_QUALIFICATION);

const mfIdentityVerification = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.IDENTITY_VERIFICATION);

const mfSentDocuments = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.SENT_DOCUMENTS);

const receivedRequest = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.RECEIVED_REQUEST);

const mfRejectedPage = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_REASONS);

const mfSendLaterButton = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.RECEIVED_REQUEST,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.DOCUMENTS,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.REFERENCE,
  );

const commonRoutes = [
  BaseRoutes.AUTOFIN_AUTO + ROUTES.WELCOME,
  BaseRoutes.AUTOFIN_AUTO + ROUTES.AUTO_EXTERNAL,
  BaseRoutes.AUTOFIN_AUTO + ROUTES.PERSONAL_INFO,
  BaseRoutes.AUTOFIN_AUTO + ROUTES.ADDRESS_INFO,
  BaseRoutes.AUTOFIN_AUTO + ROUTES.AUTO,
  BaseRoutes.AUTOFIN_AUTO + ROUTES.NIP,
  BaseRoutes.AUTOFIN_AUTO + ROUTES.INCOME,
  BaseRoutes.AUTOFIN_AUTO + ROUTES.REFERENCE,
  BaseRoutes.AUTOFIN_AUTO + ROUTES.IDENTITY_VERIFICATION,
  BaseRoutes.AUTOFIN_AUTO + ROUTES.DOCUMENTS,
  BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_REASONS,
  BaseRoutes.AUTOFIN_AUTO + ROUTES.RECEIVED_REQUEST,
];

const mfStepper = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.PERSONAL_INFO,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.ADDRESS_INFO,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.AUTO,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.NIP,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.INCOME,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.REFERENCE,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.IDENTITY_VERIFICATION,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.DOCUMENTS,
  );

const mfImage = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.WELCOME);
const mfFooter = (location: Location): boolean =>
  prefix(location, ...commonRoutes);

// We can add an auto redirect using navigateWithParams
const main = (location: Location, isMeliCompany: boolean): boolean => {
  if (
    location.pathname === BaseRoutes.AUTOFIN_AUTO ||
    location.pathname ===
      BaseRoutes.AUTOFIN_AUTO.substring(
        0,
        BaseRoutes.AUTOFIN_AUTO.length - 1,
      ) ||
    location.pathname === '/' ||
    !localStorage.getItem('publicAutofinLeadId')
  ) {
    if (isMeliCompany) {
      navigateWithParams(BaseRoutes.AUTOFIN_AUTO + ROUTES.AUTO_EXTERNAL);
    } else {
      navigateWithParams(BaseRoutes.AUTOFIN_AUTO + ROUTES.WELCOME);
    }
  }
  return true;
};

const notFound = (location: Location): boolean => {
  const isCurrentPathInRoutes = Object.values(ROUTES).some(
    (route) =>
      BaseRoutes.AUTOFIN_AUTO + route === location.pathname ||
      BaseRoutes.AUTOFIN_AUTO + route + '/' === location.pathname,
  );
  return !isCurrentPathInRoutes;
};

const navBarSupport = (location: Location): boolean =>
  prefix(location, ...commonRoutes);

export default {
  mfSendLaterButton,
  mfExternalAutoForm,
  mfWelcome,
  mfAutoForm,
  mfFormBuroQuery,
  mfAddressForm,
  mfNip,
  mfDocumentsForm,
  notFound,
  mfStepper,
  main,
  mfFormIncome,
  mfRejectedThirtyDays,
  mfPreQualification,
  mfIdentityVerification,
  mfSentDocuments,
  receivedRequest,
  mfRejectedPage,
  mfImage,
  mfFooter,
  navBarSupport,
};
